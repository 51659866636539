import TextFormatter from "./text-formatter";

const Ozgg = {
    autoInitComponents: true,
    components: {
        textFormatter: TextFormatter
    }
}

Biovision.components.ozgg = Ozgg;
